import DashboardCoordenador from 'views/coordenador/DashboardCoordenador';
import Noticias from 'views/gestor/Noticias';
import Noticia from 'views/gestor/Noticia';
import AnosLetivos from 'views/gestor/AnosLetivos';
import AnoLetivo from 'views/gestor/AnoLetivo';
import TurmasEscola from 'views/gestor/TurmasEscola';
import Turma from 'views/gestor/Turma';
import TurmaMultiseriado from 'views/gestor/TurmaMultiseriado';
import FrequenciaDiario from 'views/gestor/FrequenciaDiario';
import Depoimentos from 'views/comum/Depoimentos';
import CadastroDepoimento from 'views/comum/CadastroDepoimento';
import RelatorioContagemGeral from 'views/gestor/RelatorioContagemGeral';
import RelatorioSituacaoTurmas from 'views/gestor/RelatorioSituacaoTurmas';
import RelatorioSituacaoDiarios from 'views/gestor/RelatorioSituacaoDiarios';
import RelatorioInformacoesMatriculas from 'views/gestor/RelatorioInformacoesMatriculas';
import RelatorioMatriculasPorSerie from 'views/gestor/RelatorioMatriculasPorSerie';
import RelatorioTurmasPorSerie from 'views/gestor/RelatorioTurmasPorSerie';
import RelatorioDistorcaoIdadeSerie from 'views/gestor/RelatorioDistorcaoIdadeSerie';
import RelatorioMatriculasPorSexo from 'views/gestor/RelatorioMatriculasPorSexo';

import Diario from 'views/professor/Diario';
import DiarioAlfabetizacao from 'views/professor/DiarioAlfabetizacao';
import DiarioTurmaMultiseriado from 'views/professor/DiarioTurmaMultiseriado';
import Notas from 'views/professor/Notas';
import Relatorios from 'views/professor/Relatorios';
import Atelie from 'views/professor/Atelie';
import RelatorioAluno from 'views/professor/RelatorioAluno';
import RegistrosDiarioAluno from 'views/professor/RegistrosDiarioAluno';
import CadastroRegistroDiarioAluno from 'views/professor/CadastroRegistroDiarioAluno';
import RegistroDiarioAluno from 'views/professor/RegistroDiarioAluno';
import AvaliacaoEspecialAluno from 'views/professor/AvaliacaoEspecialAluno';
import AvaliacaoConceitual from 'views/professor/AvaliacaoConceitual';
import ConteudoLecionado from 'views/professor/ConteudoLecionado';
import ConteudoLecionadoTurmaMultiseriado from 'views/professor/ConteudoLecionadoTurmaMultiseriado';
import HabilidadesTrabalhadas from 'views/professor/HabilidadesTrabalhadas';
import HabilidadesAdquiridasAluno from 'views/professor/HabilidadesAdquiridasAluno';
import HabilidadesAdquiridasAlunoBimestres from 'views/professor/HabilidadesAdquiridasAlunoBimestres';
import CompetenciasMunicipaisAdquiridasAluno from 'views/professor/CompetenciasMunicipaisAdquiridasAluno';
import ObjetivosAprendizagemTrabalhados from 'views/professor/ObjetivosAprendizagemTrabalhados';
import ObjetivosAprendizagemAdquiridosAluno from 'views/professor/ObjetivosAprendizagemAdquiridosAluno';
import ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado';
import ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado';
import RelatorioTurma from 'views/professor/RelatorioTurma';
import RelatorioTurmaMultiseriado from 'views/professor/RelatorioTurmaMultiseriado';
import CalendarioFrequencia from 'views/professor/CalendarioFrequencia';
import CalendarioFrequenciaMultiseriado from 'views/professor/CalendarioFrequenciaMultiseriado';
import MesFrequencia from 'views/professor/MesFrequencia';
import MesFrequenciaMultiseriado from 'views/professor/MesFrequenciaMultiseriado';
import JustificativaFaltas from 'views/professor/JustificativaFaltas';
import JustificativaFaltasMultiseriado from 'views/professor/JustificativaFaltasMultiseriado';
import Abono from 'views/professor/Abono';
import AbonoMultiseriado from 'views/professor/AbonoMultiseriado';
import Frequencia from 'views/professor/Frequencia';
import FrequenciaMultiseriado from 'views/professor/FrequenciaMultiseriado';
import TurmaVirtual from 'views/professor/TurmaVirtual';
import Aula from 'views/professor/Aula';
import Tarefa from 'views/professor/Tarefa';
import EnviosAluno from 'views/professor/EnviosAluno';
import AtaFinal from 'views/professor/AtaFinal';
import AtaFinalAlfabetizacao from 'views/professor/AtaFinalAlfabetizacao';
import AtaFinalTurmaMultiseriado from 'views/professor/AtaFinalTurmaMultiseriado';
import RegistroAulas from 'views/professor/RegistroAulas';
import RegistroAulasMultiseriado from 'views/professor/RegistroAulasMultiseriado';
import RegistroAulasMultiseriadoAgrupado from 'views/professor/RegistroAulasMultiseriadoAgrupado';
import RegistroAula from 'views/professor/RegistroAula';
import RegistroAulaMultiseriadoAgrupado from 'views/professor/RegistroAulaMultiseriadoAgrupado';
import AulasProfessores from 'views/gestor/AulasProfessores';
import ListagemAulasProfessor from 'views/gestor/ListagemAulasProfessor';
import AulaProfessor from 'views/gestor/AulaProfessor';
import Tutoriais from 'views/gestor/Tutoriais';
import AgendaDiario from 'views/professor/AgendaDiario';
import AgendaDiarioMultiseriado from 'views/professor/AgendaDiarioMultiseriado';

import RelatoriosEstatisticos from 'views/gestor/RelatoriosEstatisticos';
import ImprimirRelatorioDatasAnoLetivo from 'views/gestor/ImprimirRelatorioDatasAnoLetivo';

import RelatoriosEscolasTurmas from 'views/gestor/RelatoriosEscolasTurmas';
import RelatoriosTransportes from 'views/gestor/RelatoriosTransportes';
import ImprimirRelatorioTurmasPorEscola from 'views/gestor/ImprimirRelatorioTurmasPorEscola';
import ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola from 'views/gestor/ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola';
import ImprimirRelatorioTurmasPorSerie from 'views/gestor/ImprimirRelatorioTurmasPorSerie';
import ImprimirRelatorioDiariosPorEscola from 'views/gestor/ImprimirRelatorioDiariosPorEscola';
import ImprimirRelatorioFuncionariosPorEscola from 'views/gestor/ImprimirRelatorioFuncionariosPorEscola';
import ImprimirRelatorioOcorrenciasPorEscola from 'views/gestor/ImprimirRelatorioOcorrenciasPorEscola';
import ImprimirRelatorioJustificativasFaltasPorTurma from 'views/gestor/ImprimirRelatorioJustificativasFaltasPorTurma';
import ImprimirRelatorioLivroMatriculaPorEscola from 'views/gestor/ImprimirRelatorioLivroMatriculaPorEscola';
import ImprimirRelatorioLivroMatriculaPorTurma from 'views/gestor/ImprimirRelatorioLivroMatriculaPorTurma';
import ImprimirRelatorioNotasFinaisPorTurma from 'views/gestor/ImprimirRelatorioNotasFinaisPorTurma';
import ImprimirRelatorioFichasIndividuaisPorTurma from 'views/gestor/ImprimirRelatorioFichasIndividuaisPorTurma';
import ImprimirRelatorioFrequenciasMensaisPorTurma from 'views/gestor/ImprimirRelatorioFrequenciasMensaisPorTurma';
import ImprimirRelatorioQuantidadeFrequenciaDia from 'views/gestor/ImprimirRelatorioQuantidadeFrequenciaDia';

import RelatoriosDistorcaoIdadeSerie from 'views/gestor/RelatoriosDistorcaoIdadeSerie';
import ImprimirRelatorioDistorcaoIdadeSeriePorEscola from 'views/gestor/ImprimirRelatorioDistorcaoIdadeSeriePorEscola';

import RelatoriosProfessores from 'views/gestor/RelatoriosProfessores';
import ImprimirRelatorioProfessoresPorTurma from 'views/gestor/ImprimirRelatorioProfessoresPorTurma';
import ImprimirRelatorioProfessoresPorEscola from 'views/gestor/ImprimirRelatorioProfessoresPorEscola';

import RelatoriosAlunos from 'views/gestor/RelatoriosAlunos';
import ImprimirRelatorioAlunosPorEscola from 'views/gestor/ImprimirRelatorioAlunosPorEscola';
import ImprimirRelatorioAlunosBolsaFamiliaPorEscola from 'views/gestor/ImprimirRelatorioAlunosBolsaFamiliaPorEscola';
import ImprimirRelatorioAlunosPorTurma from 'views/gestor/ImprimirRelatorioAlunosPorTurma';
import ImprimirRelatorioAlunosPnePorEscola from 'views/gestor/ImprimirRelatorioAlunosPnePorEscola';
import ImprimirRelatorioAlunosPnePorTurma from 'views/gestor/ImprimirRelatorioAlunosPnePorTurma';
import ImprimirRelatorioAlunosPorIdade from 'views/gestor/ImprimirRelatorioAlunosPorIdade';
import ImprimirRelatorioAlunosForaDeFaixaPorEscola from 'views/gestor/ImprimirRelatorioAlunosForaDeFaixaPorEscola';
import ImprimirRelatorioAlunosAbaixoDaMediaPorTurma from 'views/gestor/ImprimirRelatorioAlunosAbaixoDaMediaPorTurma';
//import ImprimirRelatorioAlunosBaixaFrequenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosBaixaFrequenciaPorTurma';
import ImprimirRelatorioHabilidadesAdquiridasAluno from 'views/gestor/ImprimirRelatorioHabilidadesAdquiridasAluno';
import ImprimirRelatorioHabilidadesAdquiridas from 'views/gestor/ImprimirRelatorioHabilidadesAdquiridas';
import ImprimirRelatorioNotasPorTurma from 'views/gestor/ImprimirRelatorioNotasPorTurma';
import ImprimirRelatorioMediasAlunosPorEscola from 'views/gestor/ImprimirRelatorioMediasAlunosPorEscola';
import ImprimirRelatorioNotasAlunosPorMateria from 'views/gestor/ImprimirRelatorioNotasAlunosPorMateria';
import ImprimirRelatorioNotasAlunosPorProfessor from 'views/gestor/ImprimirRelatorioNotasAlunosPorProfessor';
import ImprimirRelatorioNotasAlunosPorBimestre from 'views/gestor/ImprimirRelatorioNotasAlunosPorBimestre';
import ImprimirRelatorioFrequenciaAlunosPorMes from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorMes';
import ImprimirRelatorioFrequenciaAlunosPorBimestre from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorBimestre';
import ImprimirRelatorioFrequenciaAlunosPorMateria from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorMateria';
import ImprimirRelatorioContagemAlunos from 'views/gestor/ImprimirRelatorioContagemAlunos';
import ImprimirRelatorioContagemAlunosPorEscolaEtapa from 'views/gestor/ImprimirRelatorioContagemAlunosPorEscolaEtapa';
import ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola from 'views/gestor/ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola';
import ImprimirRelatorioFaltasAluno from 'views/gestor/ImprimirRelatorioFaltasAluno';
import ImprimirRelatorioPeiAluno from 'views/gestor/ImprimirRelatorioPeiAluno';
import ImprimirRelatorioAlunosPorTurnoPorEscola from 'views/gestor/ImprimirRelatorioAlunosPorTurnoPorEscola';
import ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma';
import ImprimirRelatorioAlunosAprovadosDependenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosAprovadosDependenciaPorTurma';
import ImprimirRelatorioFardamentoAlunosPorEscola from 'views/gestor/ImprimirRelatorioFardamentoAlunosPorEscola';

import RelatoriosRegistrosAula from 'views/gestor/RelatoriosRegistrosAula';
import ImprimirRelatorioRegistrosAulaPorEscola from 'views/gestor/ImprimirRelatorioRegistrosAulaPorEscola';
import ImprimirRelatorioRegistrosAulaPorTurma from 'views/gestor/ImprimirRelatorioRegistrosAulaPorTurma';
import ImprimirRelatorioRegistrosAulaPorProfessor from 'views/gestor/ImprimirRelatorioRegistrosAulaPorProfessor';
import ImprimirRelatorioQuantidadeRegistrosAulaPorEscola from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorEscola';
import ImprimirRelatorioQuantidadeRegistrosAulaPorTurma from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorTurma';
import ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor';
import ImprimirRelatorioDiariosRegistroAulasEmAtraso from 'views/gestor/ImprimirRelatorioDiariosRegistroAulasEmAtraso';

import RelatoriosEventos from 'views/gestor/RelatoriosEventos';
import ImprimirRelatorioEventosEscolaConfirmacoesPresencas from 'views/gestor/ImprimirRelatorioEventosEscolaConfirmacoesPresencas';

import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';

import Papel from 'assets/csv/papeis.json';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-line-chart',
    component: DashboardCoordenador,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'far fa-newspaper',
    component: Noticias,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/noticias/:idNoticia',
    component: Noticia,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    icon: 'fas fa-file-pdf',
    component: RelatoriosEstatisticos,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
    relatoriosCoordenadores: true,
  },
  {
    path: '/relatorios/datasanoletivo',
    component: ImprimirRelatorioDatasAnoLetivo,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/registrosaula',
    component: RelatoriosRegistrosAula,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/registrosaula/porescola',
    component: ImprimirRelatorioRegistrosAulaPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/registrosaula/porturma',
    component: ImprimirRelatorioRegistrosAulaPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/registrosaula/porprofessor',
    component: ImprimirRelatorioRegistrosAulaPorProfessor,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porescola',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porturma',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porprofessor',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/diariosregistroaulasematraso/porescola',
    component: ImprimirRelatorioDiariosRegistroAulasEmAtraso,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas',
    component: RelatoriosEscolasTurmas,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/transportes',
    component: RelatoriosTransportes,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/turmasporescola',
    component: ImprimirRelatorioTurmasPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/listagemrelatoriosturmasnaoinformadosporescola',
    component: ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/turmasporserie',
    component: ImprimirRelatorioTurmasPorSerie,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/diariosporescola',
    component: ImprimirRelatorioDiariosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/funcionariosporescola',
    component: ImprimirRelatorioFuncionariosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/ocorrenciasporescola',
    component: ImprimirRelatorioOcorrenciasPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/justificativasfaltasporturma',
    component: ImprimirRelatorioJustificativasFaltasPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/livromatriculaporescola',
    component: ImprimirRelatorioLivroMatriculaPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/livromatriculaporturma',
    component: ImprimirRelatorioLivroMatriculaPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/notasfinaisporturma',
    component: ImprimirRelatorioNotasFinaisPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/fichasindividuaisporturma',
    component: ImprimirRelatorioFichasIndividuaisPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/frequenciasmensaisporturma',
    component: ImprimirRelatorioFrequenciasMensaisPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/escolasturmas/quantidadefrequenciadia',
    component: ImprimirRelatorioQuantidadeFrequenciaDia,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie',
    component: RelatoriosDistorcaoIdadeSerie,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie/porescola',
    component: ImprimirRelatorioDistorcaoIdadeSeriePorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/professores',
    component: RelatoriosProfessores,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/professores/porturma',
    component: ImprimirRelatorioProfessoresPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/professores/porescola',
    component: ImprimirRelatorioProfessoresPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos',
    component: RelatoriosAlunos,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/porescola',
    component: ImprimirRelatorioAlunosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunosbolsafamilia/porescola',
    component: ImprimirRelatorioAlunosBolsaFamiliaPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/porturma',
    component: ImprimirRelatorioAlunosPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/pneporescola',
    component: ImprimirRelatorioAlunosPnePorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/pneporturma',
    component: ImprimirRelatorioAlunosPnePorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/poridade',
    component: ImprimirRelatorioAlunosPorIdade,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/foradefaixaporescola',
    component: ImprimirRelatorioAlunosForaDeFaixaPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/abaixodamediaporturma',
    component: ImprimirRelatorioAlunosAbaixoDaMediaPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/habilidadesadquiridasporaluno',
    component: ImprimirRelatorioHabilidadesAdquiridasAluno,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/habilidadesadquiridasporturma',
    component: ImprimirRelatorioHabilidadesAdquiridas,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/notasporturma',
    component: ImprimirRelatorioNotasPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/mediasporescola',
    component: ImprimirRelatorioMediasAlunosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/notaspormateria',
    component: ImprimirRelatorioNotasAlunosPorMateria,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/notasporprofessor',
    component: ImprimirRelatorioNotasAlunosPorProfessor,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/notasporbimestre',
    component: ImprimirRelatorioNotasAlunosPorBimestre,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/frequenciapormes',
    component: ImprimirRelatorioFrequenciaAlunosPorMes,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/frequenciaporbimestre',
    component: ImprimirRelatorioFrequenciaAlunosPorBimestre,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/frequenciapormateria',
    component: ImprimirRelatorioFrequenciaAlunosPorMateria,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/contagemporescola',
    component: ImprimirRelatorioContagemAlunos,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/contagemporescolaetapa',
    component: ImprimirRelatorioContagemAlunosPorEscolaEtapa,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/percentualaprovadoreprovadoporescola',
    component: ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/faltas',
    component: ImprimirRelatorioFaltasAluno,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/pei',
    component: ImprimirRelatorioPeiAluno,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/turno',
    component: ImprimirRelatorioAlunosPorTurnoPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/matriculados/dependencia',
    component: ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/alunos/aprovados/dependencia',
    component: ImprimirRelatorioAlunosAprovadosDependenciaPorTurma,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/fardamentoalunos/porescola',
    component: ImprimirRelatorioFardamentoAlunosPorEscola,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/eventos',
    component: RelatoriosEventos,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/relatorios/eventos/sme/presencasconfirmadas',
    component: ImprimirRelatorioEventosEscolaConfirmacoesPresencas,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos',
    name: 'Anos letivos',
    icon: 'fa fa-calendar',
    component: AnosLetivos,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo',
    component: AnoLetivo,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola',
    component: TurmasEscola,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma',
    component: Turma,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma',
    component: TurmaMultiseriado,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/ano',
    component: FrequenciaDiario,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/depoimentos',
    component: Depoimentos,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/depoimentos/cadastro',
    component: CadastroDepoimento,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/depoimentos/:idDepoimento',
    component: CadastroDepoimento,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/contagemgeral/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioContagemGeral,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/situacaoturmas/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioSituacaoTurmas,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/situacaodiarios/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioSituacaoDiarios,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/informacoesmatriculas/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioInformacoesMatriculas,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorSerie,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/turmasserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioTurmasPorSerie,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/distorcaoidadeserie/anoletivo/:idAnoLetivo',
    component: RelatorioDistorcaoIdadeSerie,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/distorcaoidadeserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioDistorcaoIdadeSerie,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporsexo/anoletivo/:idAnoLetivo',
    component: RelatorioMatriculasPorSexo,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporsexo/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorSexo,
    layout: '/coordenadorpedagogico',
    redirect: true,
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },

  // diários
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario',
    component: Diario,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao',
    component: DiarioAlfabetizacao,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario',
    component: DiarioTurmaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atelie/:idMatricula',
    component: Atelie,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/atelie/:idMatricula',
    component: Atelie,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/turma',
    component: RelatorioTurma,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/relatorios/turma',
    component: RelatorioTurmaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/:idMateria',
    component: Relatorios,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorio/aluno/:idAluno',
    component: RelatorioAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula',
    component: RegistrosDiarioAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula/cadastro',
    component: CadastroRegistroDiarioAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula/edicao/:idRegistroDiarioAluno',
    component: CadastroRegistroDiarioAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/:idRegistroDiarioAluno',
    component: RegistroDiarioAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/especial/matricula/:idMatricula',
    component: AvaliacaoEspecialAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/conceitual/materia/:idMateria',
    component: AvaliacaoConceitual,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo/:idMateria',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/conteudo',
    component: ConteudoLecionadoTurmaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades',
    component: HabilidadesTrabalhadas,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula',
    component: HabilidadesAdquiridasAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula/bimestres',
    component: HabilidadesAdquiridasAlunoBimestres,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/competencias/municipais/matricula/:idMatricula',
    component: CompetenciasMunicipaisAdquiridasAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos',
    component: ObjetivosAprendizagemTrabalhados,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos/matricula/:idMatricula',
    component: ObjetivosAprendizagemAdquiridosAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades',
    component: ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades/matricula/:idMatricula',
    component: ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia',
    component: CalendarioFrequencia,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia',
    component: CalendarioFrequenciaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/mes',
    component: MesFrequencia,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/mes',
    component: MesFrequenciaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/justificativa/faltas',
    component: JustificativaFaltas,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/justificativa/faltas',
    component: JustificativaFaltasMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/abono',
    component: Abono,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/frequencia/abono',
    component: AbonoMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/data/:data',
    component: Frequencia,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/data/:data',
    component: FrequenciaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual',
    component: TurmaVirtual,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/aula/:idAula',
    component: Aula,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa',
    component: Tarefa,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa/envios/aluno/:idAluno',
    component: EnviosAluno,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atafinal',
    component: AtaFinal,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/alfabetizacao/:idDiario/atafinal',
    component: AtaFinalAlfabetizacao,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/atafinal',
    component: AtaFinalTurmaMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas',
    component: RegistroAulas,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/registroaulas',
    component: RegistroAulasMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/registroaulas/agrupado',
    component: RegistroAulasMultiseriadoAgrupado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/:idRegistroAula',
    component: RegistroAula,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/agrupado/:idRegistroAula',
    component: RegistroAulaMultiseriadoAgrupado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/aulasprofessores',
    name: 'Aulas Virtuais',
    icon: 'fas fa-tablet-alt',
    component: AulasProfessores,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/aulasprofessores/professor/:idProfessor/listagemaulas',
    component: ListagemAulasProfessor,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/aulasprofessores/professor/:idProfessor/listagemaulas/aula/:idAula',
    component: AulaProfessor,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/tutoriais',
    component: Tutoriais,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/agenda',
    component: AgendaDiario,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/agenda',
    component: AgendaDiarioMultiseriado,
    redirect: true,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/coordenadorpedagogico',
    role: Papel.COORDENADOR_PEDAGOGICO.value,
  },
];

export { routesProfile };
export default routes;

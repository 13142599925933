import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Table,
	UncontrolledDropdown,
	UncontrolledTooltip,
} from 'reactstrap';

import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';
import TextoModal from 'views/components/TextoModal';

import AnosLetivosService from 'services/AnosLetivosService';
import DiariosService from 'services/DiariosService';
import EntesService from 'services/EntesService';
import EscolasService from 'services/EscolasService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';
import LoginService from 'services/LoginService';
import MateriasService from 'services/MateriasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';
import TurmasService from 'services/TurmasService';

import Utils from 'utils/Utils';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Papel from 'assets/csv/papeis.json';
import Serie from 'assets/csv/series2.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Turno from 'assets/csv/turnos.json';

class DiarioAlfabetizacao extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesDiario = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.state = {
      showAlert: false,
      showConfirmModal: false,
      showLoaderModal: false,
      showTextoModal: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();
    this.diariosService = new DiariosService();
    this.entesService = new EntesService();
    this.habilidadesCompetenciasEspecificasService = new
      HabilidadesCompetenciasEspecificasService();
    this.objetivosAprendizagemCampoExperienciaService = new
      ObjetivosAprendizagemCampoExperienciaService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();

    this.consolidarDiario = this.consolidarDiario.bind(this);
    this.confirmarConsolidacaoDiario = this.confirmarConsolidacaoDiario.bind(this);

    this.reabrirDiario = this.reabrirDiario.bind(this);
    this.confirmarReaberturaDiario = this.confirmarReaberturaDiario.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      if (turma.multiSeriado) {
        this.props.history.push(`${this.props.layout}`
          + `/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.props.match.params.idEscola}`
          + `/turma/multiseriado/${turma.turmaMultiseriado.id}/diario`);
        return;
      }

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      let professores = await this.turmasService
        .carregarProfessores(this.props.match.params.idTurma);

      const fn = (professor) => new Promise((resolve) => resolve(
        this.professorAuxiliarService
          .carregarProfessorAuxiliar(professor.usuario.id),
      ));

      const actions = professores.map(fn);

      const professorAuxiliar = await Promise.all(actions);

      professores.forEach((professor, idx) => {
        const auxiliar = professorAuxiliar[idx].auxiliar
          ? ' (Auxiliar)' : '';
        Object.assign(professor.usuario, {
          nome: `${professor.usuario.nome}${auxiliar}`,
        });
      });

      const materias = await this.diariosService
        .carregarMateriasDoProfessor(this.props.match.params.idDiario);

      materias.forEach((materia) => {
        const professoresSelecionados = professores
          .filter((professor) => professor.materia.id === materia.id);
        Object.assign(materia, { professores: professoresSelecionados });
      });

      professores = professores.map((professor) => professor.usuario.nome);
      professores = new Set(professores);
      professores = [...professores];

      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      let tituloTextoModal = '';
      if (turma.curso) {
        if (turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
          if (turma.curso.serie === 0 || turma.curso.serie === 1) { // Berçário 0
            tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Bebês com 0 e 1 ano';
          } else if (turma.curso.serie === 2 || turma.curso.serie === 3) { // Berçário 0
            tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Crianças bem pequenas com 2 e 3 anos';
          } else { // PRÉ1 E PRÉ2
            tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Crianças pequenas com 4 e 5 anos';
          }
        } else { // ENSINO FUNDAMENTAL 1
          tituloTextoModal = `Competências gerais propostas para os estudantes do ${turma.curso.nome}`;
        }
      }

      let conteudoTextoModal = '';
      if (turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
        conteudoTextoModal = !turma.estruturaCurricular.competenciasGerais
          ? 'Direitos de aprendizagem não informados' : turma.estruturaCurricular.competenciasGerais;
      } else {
        conteudoTextoModal = !turma.estruturaCurricular.competenciasGerais
          ? 'Competências gerais não informadas' : turma.estruturaCurricular.competenciasGerais;
      }

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        matriculas,
        materias,
        professores,
        dadosEnte,
        tituloTextoModal,
        conteudoTextoModal,
        uf: anoLetivo.ente.estado.uf,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
        avaliacaoAtelie: dadosEnte.avaliacaoAtelie && parseInt(anoLetivo.ano) >= dadosEnte.avaliacaoAtelieAno,
        registroAulas: dadosEnte.registroAulas && (!dadosEnte.registroAulasAno || parseInt(anoLetivo.ano) >= dadosEnte.registroAulasAno),
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  avaliarAluno(matricula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/relatorio`
      + `/aluno/${matricula.usuario.id}`);
  }

  diarioAluno(matricula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao/registrosdiario`
      + `/matricula/${matricula.id}`);
  }

  informarHabilidades(matricula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/relatorio`
      + `/aluno/${matricula.usuario.id}`);
  }

  lancarConteudoLecionado(materia) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/conteudo/${materia.id}`);
  }

  confirmarConsolidacaoDiario() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a consolidação do diário.',
      confirmModalTextButton: 'Consolidar',
      confirmModalAction: this.consolidarDiario,
    });
  }

  confirmarReaberturaDiario() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a reabertura do diário.',
      confirmModalTextButton: 'Reabrir',
      confirmModalAction: this.reabrirDiario,
    });
  }

  async consolidarDiario() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Consolidando diário...',
    });
    try {
      await this.diariosService.consolidarDiario(this.state.diario.id);

      this.setState({
        diario: { ...this.state.diario, situacao: SituacaoDiario.CONSOLIDADO.value },
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diário consolidado com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao consolidar diário',
      });
    }
  }

  async reabrirDiario() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Reabrindo diário...',
    });
    try {
      await this.diariosService.reabrirDiario(this.state.diario.id);

      this.setState({
        diario: { ...this.state.diario, situacao: SituacaoDiario.ABERTO.value },
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diário reaberto com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao reabrir diário',
      });
    }
  }

  abrirTurmaVirtual = (materia) => {
    this.props.history
      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
        + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${materia.id}/turmavirtual`);
  }

  lancarHabilidadesObjetivosAprendizagem() {
    if (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) {
      this.lancarObjetivosAprendizagem();
    } else {
      this.lancarHabilidades();
    }
  }

  lancarHabilidades() {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/habilidades`);
  }

  lancarObjetivosAprendizagem() {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/objetivos`);
  }

  lancarHabilidadesObjetivosAprendizagemAluno(matricula) {
    if (matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) {
      this.lancarObjetivosAprendizagemAluno(matricula);
    } else {
      if (this.state.uf !== 'PB' && !this.state.dadosEnte.habilidadesBimestres) {
        this.lancarHabilidadesAluno(matricula);
      } else {
        this.lancarHabilidadesAlunoBimestres(matricula);
      }
    }
  }

  lancarCompetenciasMunicipaisAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/competencias/municipais/matricula/${matricula.id}`);
  }

  lancarAvaliacaoEspecialAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/avaliacao/especial/matricula/${matricula.id}`);
  }

  lancarAvaliacaoConceitual(materia) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/avaliacao/conceitual/materia/${materia.id}`);
  }

  lancarHabilidadesAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/habilidades/matricula/${matricula.id}`);
  }

  lancarHabilidadesAlunoBimestres(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/habilidades/matricula/${matricula.id}/bimestres`);
  }

  lancarObjetivosAprendizagemAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/objetivos/matricula/${matricula.id}`);
  }

  verCompetenciasEspecificasCampoExperiencia(materia) {
    if (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
      this.verCampoExperiencia(materia);
    } else {
      this.verCompetenciasEspecificas(materia);
    }
  }

  async verCompetenciasEspecificas(materia) {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Acessando competências específicas...',
    });

    try {
      const competenciasEspecificas = await this.habilidadesCompetenciasEspecificasService
        .carregarCompetenciasEspecificasPorMateria(materia.id);

      this.setState({
        showLoaderModal: false,
        showTextoModal: true,
        tituloTextoModal: `Competências específicas propostas para os alunos de ${materia.nome} no ${this.state.turma.curso.nome}`,
        conteudoTextoModal: !competenciasEspecificas.descricao
          ? 'Competências específicas não informadas' : competenciasEspecificas.descricao,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar competências específicas do componente curricular',
      });
    }
  }

  async verCampoExperiencia(materia) {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Acessando direitos de aprendizagem...',
    });

    try {
      const campoExperiencia = await this.objetivosAprendizagemCampoExperienciaService
        .carregarCampoExperienciaPorMateria(materia.id);

      this.setState({
        showLoaderModal: false,
        showTextoModal: true,
        tituloTextoModal: `Direito de aprendizagem: ${materia.nome}`,
        conteudoTextoModal: !campoExperiencia.descricao
          ? 'Direitos de aprendizagem não informados' : campoExperiencia.descricao,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar direitos de aprendizagem do campo de experiências',
      });
    }
  }

  async verCompetenciasGerais() {
    this.setState({
      showTextoModal: true,
      tituloTextoModal: this.state.tituloTextoModal,
      conteudoTextoModal: this.state.conteudoTextoModal,
    });
  }

  async imprimirDiario() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo diário para pdf...',
    });
    try {
      const pdf = await this.diariosService.imprimirDiario(this.props.match.params.idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diário impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir diário',
      });
    }
  }

  async imprimirAtaFinal() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo ata final para pdf...',
    });
    try {
      const pdf = await this.diariosService.imprimirAtaFinal(this.props.match.params.idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ata final impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir ata final',
      });
    }
  }

  async imprimirQuadroFrequencia() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo quadro de frequência para pdf...',
    });

    try {
      const pdf = await this.diariosService.imprimirQuadroFrequencia(this.props.match.params.idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Quadro de frequência impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir quadro de frequência',
      });
    }
  }

  async imprimirListaAssinaturaAlunos() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo lista de assinaturas para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirListaAssinaturaAlunos(this.props.match.params.idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Lista de assinaturas impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir lista de assinaturas',
      });
    }
  }

  async imprimirRegistrosAula() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo registros de aula para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirRegistrosAula(this.props.match.params.idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registros de aula impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir registros de aula',
      });
    }
  }

  async imprimirBoletinsAlunos() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo boletins dos alunos para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirBoletinsAlunos(this.props.match.params.idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Boletins dos alunos impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir boletins dos alunos',
      });
    }
  }

  avaliarAlunoAtelie(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/atelie/${matricula.id}`);
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.diario.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                {this.state.diario.nome}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="align-items-end">
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Situação</label>
                    <Input
                      disabled
                      type="text"
                      value={this.situacoesDiario[this.state.diario.situacao].label}
                    />
                  </FormGroup>
                </Col>
                {this.state.diario.situacao === SituacaoDiario.ABERTO.value
                  && this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Col>
                    <Button color="primary" onClick={() => this.confirmarConsolidacaoDiario()}>
                      Consolidar diário
                    </Button>
                  </Col>
                }
                {this.state.turma.situacao === SituacaoTurma.ABERTA.value
                  && this.state.diario.situacao === SituacaoDiario.CONSOLIDADO.value
                  && this.props.role === Papel.PROFESSOR.value
                  && <Col>
                    <Button color="primary" onClick={() => this.confirmarReaberturaDiario()}>
                      Reabrir diário
                    </Button>
                  </Col>
                }
                <Col className="text-right">
                  <div className='buttons-row'>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="collapse"
                        id="acoesDiarioDropdownMenuLink"
                        onClick={(evt) => evt.stopPropagation()}
                        nav>
                        <Button color="defaul">
                          Opções do diário <b className="caret" />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu style={{zIndex: 20000}} aria-labelledby="acoesDiarioDropdownMenuLink" right>
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                              + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/frequencia`)} >
                          Frequência por dia
                        </DropdownItem>
                        {
                          this.state.dadosEnte.frequenciaMensal && <DropdownItem
                            onClick={() => this.props.history
                              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                                + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/frequencia/mes`)} >
                            Frequência por mês
                          </DropdownItem>
                        }
                        {
                          this.state.registroAulas && <DropdownItem
                            onClick={() => this.props.history
                              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                                + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/registroaulas`)} >
                            Registro de Aulas
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                            + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
                            + `/diario/${this.state.diario.id}/frequencia/justificativa/faltas`)}>
                          Justificativa de faltas
                        </DropdownItem>
                        {
                          this.state.anoLetivo.ano <= 2024 && <DropdownItem
                            onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                              + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
                              + `/diario/${this.state.diario.id}/frequencia/abono`)}>
                            Abono de faltas
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                              + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/relatorios/turma`)} >
                          Relatórios da turma
                        </DropdownItem>
                        {
                          !this.state.registroAulas && <DropdownItem
                            onClick={() => this.props.history
                              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                                + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/conteudo`)} >
                            Conteúdos lecionados
                          </DropdownItem>
                        }
                        {
                          this.state.dadosEnte.competenciasTurma &&
                          !this.state.registroAulas && <DropdownItem onClick={() => this.lancarHabilidadesObjetivosAprendizagem()} >
                            {
                              this.state.turma.curso.cicloEnsino
                                === CicloEnsino.ENSINO_INFANTIL.value
                                ? 'Objetivos de aprendizagem trabalhados na turma'
                                : 'Habilidades trabalhadas na turma'
                            }
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                              + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/agenda`)} >
                          Agenda
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="collapse"
                        id="acoesDiarioDropdownMenuLink"
                        onClick={(evt) => evt.stopPropagation()}
                        nav>
                        <Button color="defaul">
                          Imprimir <b className="caret" />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="acoesDiarioDropdownMenuLink" right>
                        {/* <DropdownItem
                        onClick={() => this.props.history
                          .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                            + `${this.state.escola.id}/turma/${this.state.turma.id}/diario/alfabetizacao/${this.state.diario.id}/atafinal`)} >
                        Imprimir Ata final
                      </DropdownItem>
                        */}
                        <DropdownItem
                          onClick={() => this.imprimirDiario()} >
                          Imprimir diário
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.imprimirAtaFinal()} >
                          Imprimir ata final
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.imprimirQuadroFrequencia()} >
                          Imprimir quadro de frequência
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.imprimirListaAssinaturaAlunos()} >
                          Imprimir lista de assinatura dos alunos
                        </DropdownItem>
                        {
                          this.state.registroAulas && <DropdownItem
                            onClick={() => this.imprimirRegistrosAula()} >
                            Imprimir registros de aula
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.imprimirBoletinsAlunos()} >
                          Imprimir boletins dos alunos
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Ano Letivo</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.anoLetivo.ano}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="has-label">
                    <label>Escola</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.escola.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Turma</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.turma.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Turno</label>
                    <Input
                      disabled
                      type="text"
                      value={this.turnos[this.state.turma.turno].label}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup className="has-label">
                    <label>Curso</label>
                    <InputGroup>
                      <Input
                        disabled
                        type="text"
                        value={this.state.turma.curso.nome}
                      />
                      <InputGroupAddon
                        id="btnVerCompetenciasGerais"
                        addonType="append"
                        className='clickable'
                        onClick={() => this.verCompetenciasGerais()}>
                        <InputGroupText>
                          <i className="far fa-file-alt clickable-icon" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <UncontrolledTooltip placement="bottom" target='btnVerCompetenciasGerais' delay={0}>
                        Consultar {this.state.tituloTextoModal}
                      </UncontrolledTooltip>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Tabs>
                <TabList>
                  <Tab>Alunos</Tab>
                  <Tab>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                    ? 'Campos de experiências' : 'Componentes curriculares'}</Tab>
                </TabList>
                <TabPanel>
                  
                    <Table hover>
                      <thead className="text-primary">
                        <tr>
                          <th style={{ width: '40%' }}>Aluno</th>
                          <th style={{ width: '40%' }}>Mãe</th>
                          <th className="text-center">Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.matriculas.map((matricula, idx) => (
                            <tr key={idx}>
                              <td>{matricula.usuario.nome} {matricula.usuario.pne && <i className="fal fa-universal-access fa-lg" />} {matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}</td>
                              <td>{matricula.usuario.nomeMae}
                              </td>
                              <td className="text-center">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    aria-haspopup={true}
                                    color="default"
                                    data-toggle="collapse"
                                    id={`acoesAlunoDropdownMenuLink${idx}`}
                                    onClick={(evt) => evt.stopPropagation()}
                                    nav>
                                    <Button color="defaul">
                                      Opções do aluno
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu aria-labelledby="acoesAlunoDropdownMenuLink" right>
                                    <DropdownItem
                                      onClick={() => this.avaliarAluno(matricula)}>
                                      Relatório descritivo
                                    </DropdownItem>
                                    {
                                      this.state.diario.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && <DropdownItem
                                        onClick={() => this.diarioAluno(matricula)}>
                                        Diário do aluno
                                      </DropdownItem>
                                    }
                                    {
                                      (this.state.avaliacaoAtelie && this.state.materias.some((m) => m.atelie)) && <DropdownItem
                                        onClick={() => this.avaliarAlunoAtelie(matricula)}>
                                        Avaliação ateliê
                                      </DropdownItem>
                                    }
                                    {
                                      this.state.dadosEnte.competenciasAluno && <DropdownItem
                                        onClick={() => this.lancarHabilidadesObjetivosAprendizagemAluno(matricula)}>
                                        {matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                                          ? 'Objetivos de aprendizagem adquiridos' : 'Habilidades adquiridas'}
                                      </DropdownItem>
                                    }
                                    {
                                      this.state.dadosEnte.competenciasMunicipais && <DropdownItem
                                        onClick={() => this.lancarCompetenciasMunicipaisAluno(matricula)}>
                                        Competências municipais
                                      </DropdownItem>
                                    }
                                    {
                                      this.state.dadosEnte.avaliacaoEspecial && matricula.usuario.pne && <DropdownItem
                                        onClick={() => this.lancarAvaliacaoEspecialAluno(matricula)}>
                                        PEI
                                      </DropdownItem>
                                    }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  
                </TabPanel>
                <TabPanel>
                  
                    <Table hover>
                      <thead className="text-primary">
                        <tr>
                          <th style={{ width: '30%' }}>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                            ? 'Campo de experiências' : 'Componente curricular'}</th>
                          <th style={{ width: '50%' }}>Professores</th>
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.materias.map((materia, idx) => (
                            <tr key={idx}>
                              <td>{materia.nome}</td>
                              <td>
                                {materia.professores.map((professor, idx2) => (
                                  <span key={idx2}>{professor.usuario.nome}<br /></span>
                                ))}
                              </td>
                              <td className="text-center">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    aria-haspopup={true}
                                    color="default"
                                    data-toggle="collapse"
                                    id="acoesDiarioDropdownMenuLink"
                                    onClick={(evt) => evt.stopPropagation()}
                                    nav>
                                    <Button color="defaul">
                                      Opções do {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                                        ? 'Campo de experiências' : 'Componente curricular'}<b className="caret" />
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu aria-labelledby="acoesDiarioDropdownMenuLink" right>
                                    {
                                      this.state.dadosEnte.avaliacaoConceitual && <DropdownItem
                                        onClick={() => this.lancarAvaliacaoConceitual(materia)}>
                                        Lançar avaliação conceitual
                                      </DropdownItem>
                                    }
                                    {
                                      this.state.dadosEnte.turmaVirtual && <DropdownItem
                                        onClick={() => this.abrirTurmaVirtual(materia)}>
                                        Acessar turma virtual
                                      </DropdownItem>
                                    }
                                    <DropdownItem onClick={() => this.verCompetenciasEspecificasCampoExperiencia(materia)}>
                                      {
                                        this.state.turma.curso.cicloEnsino
                                          === CicloEnsino.ENSINO_INFANTIL.value
                                          ? 'Consultar direitos de aprendizagem'
                                          : 'Consultar competências específicas'
                                      }
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  
                </TabPanel>
              </Tabs>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="2" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`)} >
                    Voltar
                  </Button>
                </Col>
                <Col md="10" className="text-right">
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.state.confirmModalAction();
          }
        }}
        confirmText={this.state.confirmModalTextButton}
        text={this.state.confirmModalText} />
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={this.state.loaderModalText} />
      <TextoModal
        isOpen={this.state.showTextoModal}
        titulo={this.state.tituloTextoModal}
        conteudo={this.state.conteudoTextoModal}
        closeCallback={() => this.setState({ showTextoModal: false })} />
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
			</div>
		);
	}
}

export default withRouter(DiarioAlfabetizacao);

import React from 'react';

import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

class Paginator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPage: this.props.selectedPage || 1,
      numberOfPages: Math.ceil(this.props.total / this.props.rowsPerPage),
      rowsPerPage: this.props.rowsPerPage,
      total: this.props.total,
    };
		
    this.numberOfPages = Math.ceil(this.props.total / this.props.rowsPerPage),
    this.selectedPage = this.props.selectedPage || 1,  
    this.start = Math.max(this.props.selectedPage - 6, 1);
    this.end = Math.min(this.start + 6, this.numberOfPages);
  }

	componentDidUpdate(prevProps) {
		if (this.props.total !== prevProps.total
			|| this.props.rowsPerPage !== prevProps.rowsPerPage
			|| this.props.selectedPage !== prevProps.selectedPage) {
			
			this.numberOfPages = Math.ceil(this.props.total / this.props.rowsPerPage);
			this.selectedPage = this.props.selectedPage > this.numberOfPages ? this.numberOfPages : this.selectedPage,
			this.start = Math.max(this.selectedPage - 6, 1);
			this.end = Math.min(this.start + 6, this.numberOfPages);
		}
	}

  paginatorClicked(page) {
    if (page !== this.selectedPage && page >= 1 && page <= this.numberOfPages) {
      const diff1 = this.end - page;
      const diff2 = page - this.start;
      if (diff1 < 3) {
        this.end = Math.min(this.end + 3 - diff1, this.numberOfPages);
        this.start = Math.max(this.end - 6, 1);
      } else if (diff2 < 3) {
        this.start = Math.max(this.start - (3 - diff2), 1);
        this.end = Math.min(this.start + 6, this.numberOfPages);
      }
      this.setState({ selectedPage: page });
      this.props.callback(page);
    }
  }

  createPaginator() {
    const paginator = [];
    for (let i = this.start; i <= this.end; i += 1) {
      paginator.push(
        <PaginationItem key={i} active={i === parseInt(this.selectedPage, 10)}>
          <PaginationLink style={{ width: 50 }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(i);
          }}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    return paginator;
  }

  render() {
    return (
      <Pagination className="pagination-blue">
        <PaginationItem disabled={this.selectedPage === 1} >
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(1);
          }}>
            &laquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={this.selectedPage === 1} >
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(this.selectedPage - 1);
          }}>
            &lsaquo;
          </PaginationLink>
        </PaginationItem>
        {this.createPaginator()}
        <PaginationItem disabled={this.selectedPage === this.numberOfPages}>
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(this.selectedPage + 1);
          }}>
            &rsaquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={this.selectedPage === this.numberOfPages}>
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(this.numberOfPages);
          }}>
            &raquo;
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}

export default Paginator;

/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import RelatorioAlunoTurmaMultiseriado from 'views/components/RelatorioAlunoTurmaMultiseriado';
import NotaTurmaMultiseriado from 'views/components/NotaTurmaMultiseriado';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';

import Turno from 'assets/csv/turnos.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Papel from 'assets/csv/papeis.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class DiarioTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesDiario = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();

    this.consolidarDiario = this.consolidarDiario.bind(this);
    this.reabrirDiario = this.reabrirDiario.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {

      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

			const turmas = await this.turmasService.carregarTurmasDaTurmaMultiseriado(turma.id);

      Object.assign(turma, { turmas });

      const diarios = await this.diariosService
        .carregarDiariosDaTurmaMultiseriado(this.props.match.params.idTurma);
      
      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diarios,
        diario: diarios[0],
        registroAulas: dadosEnte.registroAulas && (!dadosEnte.registroAulasAno || parseInt(anoLetivo.ano) >= dadosEnte.registroAulasAno),
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  confirmarConsolidacaoDiario() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a consolidação do diário.',
      confirmModalTextButton: 'Consolidar',
      confirmModalAction: this.consolidarDiario,
    });
  }

  confirmarReaberturaDiario() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a reabertura do diário.',
      confirmModalTextButton: 'Reabrir',
      confirmModalAction: this.reabrirDiario,
    });
  }

  async consolidarDiario() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Consolidando diário...',
    });
    try {
      await this.diariosService.consolidarDiarioTurmaMultiseriado(this.state.turma.id);

      this.setState({
        diario: { ...this.state.diario, situacao: SituacaoDiario.CONSOLIDADO.value },
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diário consolidado com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao consolidar diário',
      });
    }
  }

  async reabrirDiario() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Reabrindo diário...',
    });
    try {
      await this.diariosService.reabrirDiarioTurmaMultiseriado(this.state.turma.id);

      this.setState({
        diario: { ...this.state.diario, situacao: SituacaoDiario.ABERTO.value },
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diário reaberto com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao reabrir diário',
      });
    }
  }

  async imprimirDiarioMultiseriado() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo diários para pdf...',
    });
    try {
      const pdfs = await this.diariosService.imprimirDiarioMultiseriado(this.props.match.params.idTurma);

      pdfs.forEach((pdf) => {
        const file = Utils.base64ToBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diários impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir diários',
      });
    }
  }

  async imprimirAtaFinalMultiseriado() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo atas finais para pdf...',
    });
    try {
      const pdfs = await this.diariosService.imprimirAtaFinalMultiseriado(this.props.match.params.idTurma);
      pdfs.forEach((pdf) => {
        const file = Utils.base64ToBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Atas finais impressas com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir atas finais',
      });
    }
  }

  lancarHabilidadesObjetivosAprendizagem() {
    const address = `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/multiseriado/${this.props.match.params.idTurma}/diario/objetivos/habilidades`;
    this.props.history.push(address);
  }

  async imprimirQuadroFrequencia() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo quadro de frequência para pdf...',
    });

    try {
      const pdf = await this.diariosService.imprimirQuadroFrequenciaMultiseriado(this.props.match.params.idTurma);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Quadro de frequência impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir quadro de frequência',
      });
    }
  }

  async imprimirListaAssinaturaAlunos() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo lista de assinaturas para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirListaAssinaturaAlunosMultiseriado(this.props.match.params.idTurma);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Lista de assinaturas impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir lista de assinaturas',
      });
    }
  }

  async imprimirRegistrosAula() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo registros de aula para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirRegistrosAulaMultiseriado(this.props.match.params.idTurma);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registros de aula impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir registros de aula',
      });
    }
  }

  async imprimirBoletinsAlunosMultiseriado() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo boletins dos alunos para pdf...',
      showAlert: false,
    });

    try {
      const pdfs = await this.diariosService.imprimirBoletinsAlunosMultiseriado(this.props.match.params.idTurma);

      pdfs.forEach((pdf) => {
        const file = Utils.base64ToBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Boletins dos alunos impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir boletins dos alunos',
      });
    }
  }

  abrirRegistrosAulaMultiseriado() {
    if (parseInt(this.state.anoLetivo.ano) <= 2024) {
      this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
        + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/registroaulas`)
    } else {
      this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
        + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/registroaulas/agrupado`)
    }
    
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Diário único</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Diário único
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="align-items-end">
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Situação</label>
                    <Input
                      disabled
                      type="text"
                      value={this.situacoesDiario[this.state.diario.situacao].label}
                    />
                  </FormGroup>
                </Col>
                {this.state.diario.situacao === SituacaoDiario.ABERTO.value
                  && this.props.role === Papel.PROFESSOR.value
                  && <Col>
                    <Button color="primary" onClick={() => this.confirmarConsolidacaoDiario()}>
                      Consolidar diário
                    </Button>
                  </Col>
                }
                {this.state.turma.situacao === SituacaoTurma.ABERTA.value
                  && this.state.diario.situacao === SituacaoDiario.CONSOLIDADO.value
                  && this.props.role === Papel.PROFESSOR.value
                  && <Col>
                    <Button color="primary" onClick={() => this.confirmarReaberturaDiario()}>
                      Reabrir diário
                    </Button>
                  </Col>
                }
                <Col className="text-right">
                  <div className='buttons-row'>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="collapse"
                        id="acoesDiarioDropdownMenuLink"
                        onClick={(evt) => evt.stopPropagation()}
                        nav>
                        <Button color="defaul">
                          Opções do diário<b className="caret" />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu style={{ zIndex: 20000 }} aria-labelledby="acoesDiarioDropdownMenuLink" right>
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                              + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/frequencia`)} >
                          Frequência por dia
                        </DropdownItem>
                        {
                          this.state.dadosEnte.frequenciaMensal && <DropdownItem
                            onClick={() => this.props.history
                              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                                + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/frequencia/mes`)} >
                            Frequência por mês
                          </DropdownItem>
                        }
                        {
                          this.state.registroAulas && <DropdownItem
                            onClick={() => this.abrirRegistrosAulaMultiseriado()} >
                            Registro de Aulas
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                              + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/frequencia/justificativa/faltas`)} >
                          Justificativa de faltas
                        </DropdownItem>
                        {
                          this.state.anoLetivo.ano <= 2024 && <DropdownItem
                            onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                              + `/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`
                              + '/frequencia/abono')}>
                            Abono de faltas
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                              + `/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`
                              + '/diario/relatorios/turma')} >
                          Relatórios da turma
                        </DropdownItem>
                        {
                          !this.state.registroAulas && <DropdownItem
                            onClick={() => this.props.history
                              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                                + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/conteudo`)} >
                            Conteúdos lecionados
                          </DropdownItem>
                        }
                        {
                          this.state.dadosEnte.competenciasTurma &&
                          !this.state.registroAulas &&
                          this.state.anoLetivo.ano <= 2024 && <DropdownItem onClick={() => this.lancarHabilidadesObjetivosAprendizagem()} >
                            Lançar objetivos de aprendizagem<br />/Habilidades trabalhadas na turma
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.props.history
                            .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                              + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/agenda`)} >
                          Agenda
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="collapse"
                        id="acoesDiarioDropdownMenuLink"
                        onClick={(evt) => evt.stopPropagation()}
                        nav>
                        <Button color="defaul">
                          Imprimir<b className="caret" />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="acoesDiarioDropdownMenuLink" right>
                        {/*
                          <DropdownItem
                            onClick={() => this.props.history
                              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
                                + `${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario/atafinal`)} >
                            Ata final
                          </DropdownItem>
                        */}
                        <DropdownItem
                          onClick={() => this.imprimirDiarioMultiseriado()} >
                          Imprimir diário
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.imprimirAtaFinalMultiseriado()} >
                          Imprimir ata final
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.imprimirQuadroFrequencia()} >
                          Imprimir quadro de frequência
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.imprimirListaAssinaturaAlunos()} >
                          Imprimir lista de assinatura dos alunos
                        </DropdownItem>
                        {
                          this.state.registroAulas && <DropdownItem
                            onClick={() => this.imprimirRegistrosAula()} >
                            Imprimir registros de aula
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={() => this.imprimirBoletinsAlunosMultiseriado()} >
                          Imprimir boletins dos alunos
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Ano Letivo</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.anoLetivo.ano}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="has-label">
                    <label>Escola</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.escola.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Turma</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.turma.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className="has-label">
                    <label>Turno</label>
                    <Input
                      disabled
                      type="text"
                      value={this.turnos[this.state.turma.turno].label}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Tabs>
                    <TabList>
                      {
                        this.state.turma.turmas.map((turma, idx) => <Tab key={idx}>{turma.curso.nome}</Tab>)
                      }
                    </TabList>
                    {
                      this.state.turma.turmas.map((turma, idx) => <TabPanel key={idx}>
                        {
                          turma.tipoAvaliacao === TipoAvaliacao.RELATORIO.value
                            ? <RelatorioAlunoTurmaMultiseriado
                              {...this.props}
                              idAnoLetivo={this.state.anoLetivo.id}
                              idEscola={this.state.escola.id}
                              idTurma={turma.id} />
                            : <NotaTurmaMultiseriado
                              {...this.props}
                              idAnoLetivo={this.state.anoLetivo.id}
                              idEscola={this.state.escola.id}
                              idTurma={turma.id} />
                        }
                      </TabPanel>)
                    }
                  </Tabs>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.state.confirmModalAction();
            }
          }}
          confirmText={this.state.confirmModalTextButton}
          text={this.state.confirmModalText} />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(DiarioTurmaMultiseriado);

import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
	Row,
	Col,
	Button,
	Card,
	CardBody,
	Breadcrumb,
	BreadcrumbItem,
} from 'reactstrap';

import {
	Tabs,
	TabList,
	Tab,
	TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import Turmas from 'views/components/Turmas';
import TurmasMultiseriado from 'views/components/TurmasMultiseriado';
import Ocorrencias from 'views/components/Ocorrencias';
import ArquivosEscola from 'views/components/ArquivosEscola';
import AgendaEscola from 'views/components/AgendaEscola';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';

import Papel from 'assets/csv/papeis.json';

class TurmasEscola extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			erro: null,

			showAlert: false,
		};

		this.loginService = new LoginService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);
		try {
			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const escola = await this.escolasService
				.carregarEscola(this.props.match.params.idEscola);

			const totalAlunos = await this.anosLetivosService
				.carregarTotalAlunosAtivosPorAnoLetivoEEscola(this.props.match.params.idAnoLetivo,
					this.props.match.params.idEscola, '');

			this.setState({
				anoLetivo,
				escola,
				totalAlunos,
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	conteudoPagina() {
		return this.state.escola ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
						<BreadcrumbItem active>{this.state.escola.nome}</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<Row>
								<Col>
									<h5>{`Alunos ativos: ${this.state.totalAlunos}`}</h5>
								</Col>
							</Row>
							<Tabs>
								<TabList>
									<Tab>Turmas</Tab>
									<Tab>Turmas multisseriadas</Tab>
									{
										(this.props.role === Papel.GESTOR.value
											|| this.props.role === Papel.SECRETARIO.value
											|| this.props.role === Papel.DIRETOR.value) && <Tab>Ocorrências</Tab>
									}
									{
										(this.props.role === Papel.GESTOR.value || this.props.role === Papel.DIRETOR.value ||
											this.props.role === Papel.SECRETARIO.value || this.props.role === Papel.COORDENADOR_PEDAGOGICO.value) && <Tab>Agenda da escola</Tab>
									}
									<Tab>Arquivos</Tab>
								</TabList>
								<TabPanel>
									<br />
									<Turmas
										{...this.props}
										callbackMessage={(msg) => this.setState(msg)} />
									<Row>
										<Col md="6" className="text-left">
											<Button
												color="primary" disabled={this.state.buttonDisabled}
												onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`)} >
												Voltar
											</Button>
										</Col>
										{
											(this.props.role === Papel.GESTOR.value || this.props.role === Papel.DIRETOR.value || this.props.role === Papel.SECRETARIO.value) && <Col md="6" className="text-right">
												<Button color="primary"
													onClick={() => {
														this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.props.match.params.idEscola}/turmas/cadastro` });
													}}>
													Cadastrar turma
												</Button>
											</Col>
										}
									</Row>
								</TabPanel>
								<TabPanel>
									<br />
									<TurmasMultiseriado
										{...this.props}
										callbackMessage={(msg) => this.setState(msg)} />
									<Row>
										<Col md="6" className="text-left">
											<Button
												color="primary" disabled={this.state.buttonDisabled}
												onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`)} >
												Voltar
											</Button>
										</Col>
										{
											(this.props.role === Papel.GESTOR.value || this.props.role === Papel.DIRETOR.value || this.props.role === Papel.SECRETARIO.value) && <Col md="6" className="text-right">
												<Button color="primary"
													onClick={() => {
														this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.props.match.params.idEscola}/turmas/multiseriado/cadastro` });
													}}>
													Cadastrar turma multisseriada
												</Button>
											</Col>
										}
									</Row>
								</TabPanel>
								{
									(this.props.role === Papel.GESTOR.value
										|| this.props.role === Papel.DIRETOR.value
										|| this.props.role === Papel.SECRETARIO.value)
									&& <TabPanel>
										<br />
										<Ocorrencias
											{...this.props}
											callbackMessage={(msg) => this.setState(msg)} />
										<Row>
											<Col md="6" className="text-left">
												<Button
													color="primary" disabled={this.state.buttonDisabled}
													onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`)} >
													Voltar
												</Button>
											</Col>
											{
												(this.props.role === Papel.GESTOR.value || this.props.role === Papel.DIRETOR.value || this.props.role === Papel.SECRETARIO.value) && <Col md="6" className="text-right">
													<Button color="primary"
														onClick={() => {
															this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.props.match.params.idEscola}/ocorrencias/cadastro` });
														}}>
														Cadastrar ocorrência
													</Button>
												</Col>
											}
										</Row>
									</TabPanel>
								}
								{
									(this.props.role === Papel.GESTOR.value || this.props.role === Papel.DIRETOR.value ||
										this.props.role === Papel.SECRETARIO.value || this.props.role === Papel.COORDENADOR_PEDAGOGICO.value) && <TabPanel>
										<AgendaEscola
											{...this.props}
											callbackMessage={(message) => this.setState(message)}
											idAnoLetivo={this.props.match.params.idAnoLetivo}
											idEscola={this.props.match.params.idEscola}
										/>
									</TabPanel>
								}
								<TabPanel>
									<ArquivosEscola
										{...this.props}
										callbackMessage={(message) => this.setState(message)}
										idAnoLetivo={this.props.match.params.idAnoLetivo}
										idEscola={this.props.match.params.idEscola}
									/>
								</TabPanel>
							</Tabs>
						</CardBody>
					</Card>
				</Col >
			</Row >
		</>
			: <Card>
				<div align="center" style={{ margin: 50 }}>
					<Loader
						type="Oval"
						color="#34B5B8"
						height="50"
						width="50" />
				</div>
			</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da escola
						</div>
					</Card>
				}
			</div>
		);
	}
}

export default withRouter(TurmasEscola);

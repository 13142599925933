import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Input,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import PhotoGallery from 'views/components/PhotoGallery';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import UsuarioService from 'services/UsuarioService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';
import FeriadosService from 'services/FeriadosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';
import RegistroDiarioAlunoService from 'services/RegistroDiarioAlunoService';
import AwsService from 'services/AwsService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class DiarioAluno extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.cadastrarRegistroDiarioAluno = this.cadastrarRegistroDiarioAluno.bind(this);
    this.atualizarRegistroDiarioAluno = this.atualizarRegistroDiarioAluno.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      informacoes: '',
      imagensSelecionadas: [],

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.usuarioService = new UsuarioService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
    this.feriadosService = new FeriadosService();
    this.diasCanceladosService = new DiasCanceladosService();
    this.diasExtrasService = new DiasExtrasService();
    this.registroDiarioAlunoService = new RegistroDiarioAlunoService();
    this.awsService = new AwsService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const matricula = await this.turmasService
        .carregarMatricula(this.props.match.params.idTurma, this.props.match.params.idMatricula);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      matriculas.forEach((matricula) => {
        Object.assign(matricula, {
          label: matricula.usuario.nome,
          value: matricula.id,
        });
      });

      const matriculasSelecionadas = [{
        ...matricula,
        label: matricula.usuario.nome,
        value: matricula.id,
      }];

      if (this.props.match.params.idRegistroDiarioAluno) {
        const registroDiarioAluno = await this.registroDiarioAlunoService
          .carregarRegistroDiarioAluno(this.props.match.params.idRegistroDiarioAluno);

        const fotos = await this.registroDiarioAlunoService
          .carregarRegistroDiarioAlunoFotos(this.props.match.params.idRegistroDiarioAluno);

        const urlPromises = fotos.map((foto) => new Promise(async (resolve) => {
          const url = await this.awsService.getPresignedUrl(foto.url);
          resolve(url);
        }));
    
        const urls = await Promise.all(urlPromises);
    
        const imagens = fotos.map((foto, idx) => ({
          ...foto,
          src: urls[idx],
        }));

        const data = moment(registroDiarioAluno.data, 'DD/MM/YYYY');
        const diaDaSemana = data.format('ddd').toUpperCase();

        const dataSelecionada = {
          value: registroDiarioAluno.data,
          label: `${registroDiarioAluno.data} - ${diaDaSemana}`
        };

        this.setState({
          informacoes: registroDiarioAluno.informacoes,
          imagensSelecionadas: imagens,
          dataSelecionada,
          registroDiarioAlunoSelecionado: registroDiarioAluno,
        })
      }

			const diasUteis = await this.anosLetivosService
				.carregarDiasUteisAnoLetivo(this.props.match.params.idAnoLetivo);

			const dias = await this.diariosService
        .carregarDiasDeAulaDoProfessor(this.props.match.params.idDiario);
			
      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        matricula,
        matriculasSelecionadas,
        selectMatriculas: matriculas,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
				diasUteis,
				dias,
      }, () => {
				const selectDatas = [];

				let inicio = moment(anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY');
				const fim = moment(anoLetivo.dataFimBimestre4, 'DD-MM-YYYY');
	
				while (!inicio.isAfter(fim)) {
					if (this.diaUtil(inicio.toDate())) {
						selectDatas.push({
							value: inicio.format("DD/MM/YYYY"),
							label: inicio.format("DD/MM/YYYY - ddd").toUpperCase(),
						});
					}
					inicio.add(1, 'days');
				}
	
				this.setState({
					selectDatas,
				});
			});
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  diaUtil(date) {
		const dateStr = moment(date).format('DD-MM-YYYY');
		const diaAula = !this.state.turma.frequenciaRestrita || this.state.dias.some((d) => d === (date.getDay() - 1));
		const diaUtil = this.state.diasUteis.some((d) => d === dateStr);
		return diaAula && diaUtil;
	}

  validarCampos() {
    let ret = true;

    if (!this.state.matriculasSelecionadas || this.state.matriculasSelecionadas.length === 0) {
      this.setState({ matriculasSelecionadasState: 'danger' });
      ret = false;
    }

    if (!this.state.dataSelecionada) {
      this.setState({ dataSelecionadaState: 'danger' });
      ret = false;
    }

    if (!(this.state.informacoes.trim())) {
      this.setState({ informacoesState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  async cadastrarRegistroDiarioAluno() {
    if (!this.validarCampos()) return;
    if (this.props.match.params.idRegistroDiarioAluno) return this.atualizarRegistroDiarioAluno();

    this.setState({
      showLoaderModal: true,
      showAlert: false,
    });

    try {
      const fotos = this.state.imagensSelecionadas.map((imagem) => ({
        conteudo: imagem.src.replace(/^data:.*;base64,/, ''),
        width: imagem.width,
        height: imagem.height,
      }));

      const registrosDiarioAlunos = this.state.matriculasSelecionadas.map((matricula) => ({
        data: this.state.dataSelecionada.value,
        matricula,
        informacoes: this.state.informacoes,
        fotos,
      }));

      await this.registroDiarioAlunoService.cadastrarRegistrosDiarioAlunos(this.props.match.params.idDiario, registrosDiarioAlunos);

      this.limparCampos();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro do diário cadastrado com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar registro do diário',
      });
    }
  }

  async atualizarRegistroDiarioAluno() {
    this.setState({
      showLoaderModal: true,
      showAlert: false,
    });

    try {
      const fotos = this.state.imagensSelecionadas.map((imagem) => ({
        id: imagem.id,
        conteudo: imagem.src.replace(/^data:.*;base64,/, ''),
        width: imagem.width,
        height: imagem.height,
      }));

      const registroDiarioAluno = ({
        ...this.state.registroDiarioAlunoSelecionado,
        id: this.state.registroDiarioAlunoSelecionado.id,
        data: this.state.dataSelecionada.value,
        matricula: this.state.matricula,
        informacoes: this.state.informacoes,
        fotos,
      });

      await this.registroDiarioAlunoService.atualizarRegistroDiarioAluno(this.props.match.params.idDiario, registroDiarioAluno);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro do diário atualizado com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar registro do diário',
      });
    }
  }

  limparCampos() {
    this.setState({
      matriculasSelecionadas: [{
        ...this.state.matricula,
        label: this.state.matricula.usuario.nome,
        value: this.state.matricula.id,
      }],
      dataSelecionada: '',
      informacoes: '',
      imagensSelecionadas: [],
    })
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`
      + `/registrosdiario/matricula/${this.props.match.params.idMatricula}`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>Cadastro Registro de Diário do Aluno</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Cadastro de Registro de Diário do aluno
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="11">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Alunos *</label>
                    <Select
                      isDisabled={this.props.match.params.idRegistroDiarioAluno}
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.matriculasSelecionadasState}`}
                      classNamePrefix="react-select"
                      placeholder="Alunos..."
                      closeMenuOnSelect={false}
                      isMulti
                      value={this.state.matriculasSelecionadas}
                      onChange={(event) => {
                        this.setState({
                          matriculasSelecionadas: event || [],
                          matriculasSelecionadasState: '',
                        });
                      }}
                      options={this.state.selectMatriculas}
                    />
                    {this.state.matriculasSelecionadasState === 'danger' ? (
                      <label className="error">
                        Informe pelo menos um aluno
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                {
                  !this.props.match.params.idRegistroDiarioAluno && <Col md="1" className="mt-3">
                    <Button
                      id="btnSelecionarTudo"
                      color="primary"
                      onClick={() => this.setState({ matriculasSelecionadas: this.state.selectMatriculas })}>
                      <i className="fad fa-check-double"></i>
                    </Button>
                    <UncontrolledTooltip target="btnSelecionarTudo" placement="bottom" >
                      Selecionar todos os alunos
                    </UncontrolledTooltip>
                  </Col>
                }
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Data *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.dataSelecionadaState}`}
                      classNamePrefix="react-select"
                      value={this.state.dataSelecionada}
                      onChange={async (event) => {
                        this.setState({
                          dataSelecionada: event,
                          dataSelecionadaState: 'primary',
                        });
                      }}
                      options={this.state.selectDatas}
                      placeholder="Data..."
                    />
                    {this.state.dataSelecionadaState === 'danger' ? (
                      <label className="error">
                        Informe a data do registro
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              {
                this.state.loadingDiarioAluno ? <div align="center" style={{ padding: 30 }}>
                  <Loader
                    type="Oval"
                    color="#34B5B8"
                    height="50"
                    width="50" />
                </div> : <Row>
                  <Col className="mt-3" md="12">
                    <Row>
                      <Col>
                        <FormGroup className={`has-label ${this.state.informacoesState}`}>
                          <label>Informações do dia *</label>
                          <Input
                            disabled={this.props.role !== Papel.PROFESSOR.value}
                            value={this.state.informacoes}
                            onChange={(evt) => {
                              this.setState({
                                informacoes: evt.target.value,
                                informacoesState: '',
                              })
                            }}
                            type="textarea" />
                          {this.state.informacoesState === 'has-danger' ? (
                            <label className="error">
                              Informe as informações do registro.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PhotoGallery
                          selectMode={this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado}
                          images={this.state.imagensSelecionadas}
                          onSelect={(images) => this.setState({ imagensSelecionadas: images })}
                        />
                      </Col>
                    </Row>
                    <div className="category form-category">
                      * Campos obrigatórios
                    </div>
                  </Col>
                </Row>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      color="primary"
                      disabled={this.state.showLoaderModal}
                      onClick={this.cadastrarRegistroDiarioAluno} >
                      {
                        !this.props.match.params.idRegistroDiarioAluno ? 'Cadastrar registro de diário do aluno' : 'Atualizar registro de diário do aluno'
                      }
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando registro de diário do aluno..." />
      </div>
    );
  }
}

export default withRouter(DiarioAluno);

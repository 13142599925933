import DashboardProfessor from 'views/professor/DashboardProfessor';
import Noticias from 'views/gestor/Noticias';
import Noticia from 'views/gestor/Noticia';
import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';

import AnosLetivos from 'views/gestor/AnosLetivos';
import AnoLetivo from 'views/gestor/AnoLetivo';
import TurmasEscola from 'views/gestor/TurmasEscola';
import Turma from 'views/gestor/Turma';
import TurmaMultiseriado from 'views/gestor/TurmaMultiseriado';
import Diario from 'views/professor/Diario';
import DiarioAlfabetizacao from 'views/professor/DiarioAlfabetizacao';
import DiarioTurmaMultiseriado from 'views/professor/DiarioTurmaMultiseriado';
import Notas from 'views/professor/Notas';
import Relatorios from 'views/professor/Relatorios';
import Atelie from 'views/professor/Atelie';
import RelatorioAluno from 'views/professor/RelatorioAluno';
import RegistrosDiarioAluno from 'views/professor/RegistrosDiarioAluno';
import CadastroRegistroDiarioAluno from 'views/professor/CadastroRegistroDiarioAluno';
import RegistroDiarioAluno from 'views/professor/RegistroDiarioAluno';
import AvaliacaoEspecialAluno from 'views/professor/AvaliacaoEspecialAluno';
import AvaliacaoConceitual from 'views/professor/AvaliacaoConceitual';
import ConteudoLecionado from 'views/professor/ConteudoLecionado';
import ConteudoLecionadoTurmaMultiseriado from 'views/professor/ConteudoLecionadoTurmaMultiseriado';
import HabilidadesTrabalhadas from 'views/professor/HabilidadesTrabalhadas';
import RegistroAulas from 'views/professor/RegistroAulas';
import RegistroAulasMultiseriado from 'views/professor/RegistroAulasMultiseriado';
import RegistroAulasMultiseriadoAgrupado from 'views/professor/RegistroAulasMultiseriadoAgrupado';
import CadastroRegistroAula from 'views/professor/CadastroRegistroAula';
import CadastroRegistroAulaMultiseriadoAgrupado from 'views/professor/CadastroRegistroAulaMultiseriadoAgrupado';
import RegistroAula from 'views/professor/RegistroAula';
import RegistroAulaMultiseriadoAgrupado from 'views/professor/RegistroAulaMultiseriadoAgrupado';
import HabilidadesAdquiridasAluno from 'views/professor/HabilidadesAdquiridasAluno';
import HabilidadesAdquiridasAlunoBimestres from 'views/professor/HabilidadesAdquiridasAlunoBimestres';
import CompetenciasMunicipaisAdquiridasAluno from 'views/professor/CompetenciasMunicipaisAdquiridasAluno';
import HabilidadesAdquiridasAlunoMultiseriado from 'views/professor/HabilidadesAdquiridasAlunoMultiseriado';
import ObjetivosAprendizagemTrabalhados from 'views/professor/ObjetivosAprendizagemTrabalhados';
//import ObjetivosAprendizagemTrabalhadasMultiseriado atendido por HabilidadesTrabalhadasMultiseriado HabilidadesTrabalhadasMultiseriado
import ObjetivosAprendizagemAdquiridosAluno from 'views/professor/ObjetivosAprendizagemAdquiridosAluno';

import ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado';
import ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado';

import RelatorioTurma from 'views/professor/RelatorioTurma';
import RelatorioTurmaMultiseriado from 'views/professor/RelatorioTurmaMultiseriado';
import CalendarioFrequencia from 'views/professor/CalendarioFrequencia';
import CalendarioFrequenciaMultiseriado from 'views/professor/CalendarioFrequenciaMultiseriado';
import MesFrequencia from 'views/professor/MesFrequencia';
import MesFrequenciaMultiseriado from 'views/professor/MesFrequenciaMultiseriado';
import JustificativaFaltas from 'views/professor/JustificativaFaltas';
import JustificativaFaltasMultiseriado from 'views/professor/JustificativaFaltasMultiseriado';
import Abono from 'views/professor/Abono';
import AbonoMultiseriado from 'views/professor/AbonoMultiseriado';
import Frequencia from 'views/professor/Frequencia';
import FrequenciaMultiseriado from 'views/professor/FrequenciaMultiseriado';
import TurmaVirtual from 'views/professor/TurmaVirtual';
import CadastroAula from 'views/professor/CadastroAula';
import Aula from 'views/professor/Aula';
import TurmasVirtuais from 'views/professor/TurmasVirtuais';
import CadastroTarefa from 'views/professor/CadastroTarefa';
import Tarefa from 'views/professor/Tarefa';
import EnviosAluno from 'views/professor/EnviosAluno';
import AtaFinal from 'views/professor/AtaFinal';
import AtaFinalAlfabetizacao from 'views/professor/AtaFinalAlfabetizacao';
import AtaFinalTurmaMultiseriado from 'views/professor/AtaFinalTurmaMultiseriado';
import Depoimentos from 'views/comum/Depoimentos';
import CadastroDepoimento from 'views/comum/CadastroDepoimento';
import Ouvidoria from 'views/gestor/Ouvidoria';
import CadastroChamadoOuvidoria from 'views/gestor/CadastroChamadoOuvidoria';
import ChamadoOuvidoria from 'views/gestor/ChamadoOuvidoria';
import RelatorioInformacoesMatriculas from 'views/gestor/RelatorioInformacoesMatriculas';
import DiariosProfessor from 'views/professor/DiariosProfessor';
import AmbienteVirtual from 'views/professor/AmbienteVirtual';
import ListagemAulas from 'views/professor/ListagemAulas';
import ContatoLogado from 'views/comum/ContatoLogado';
import Tutoriais from 'views/gestor/Tutoriais';
import Papel from 'assets/csv/papeis.json';
import AgendaDiario from 'views/professor/AgendaDiario';
import AgendaDiarioMultiseriado from 'views/professor/AgendaDiarioMultiseriado';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-line-chart',
    component: DashboardProfessor,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'far fa-newspaper',
    component: Noticias,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/noticias/:idNoticia',
    component: Noticia,
    layout: '/professor',
    redirect: true,
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos',
    name: 'Anos Letivos',
    icon: 'fa fa-calendar',
    component: AnosLetivos,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo',
    component: AnoLetivo,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola',
    component: TurmasEscola,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma',
    component: Turma,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma',
    component: TurmaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario',
    component: Diario,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao',
    component: DiarioAlfabetizacao,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario',
    component: DiarioTurmaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atelie/:idMatricula',
    component: Atelie,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/atelie/:idMatricula',
    component: Atelie,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/turma',
    component: RelatorioTurma,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/relatorios/turma',
    component: RelatorioTurmaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/:idMateria',
    component: Relatorios,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorio/aluno/:idAluno',
    component: RelatorioAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula',
    component: RegistrosDiarioAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula/cadastro',
    component: CadastroRegistroDiarioAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula/edicao/:idRegistroDiarioAluno',
    component: CadastroRegistroDiarioAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/:idRegistroDiarioAluno',
    component: RegistroDiarioAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/especial/matricula/:idMatricula',
    component: AvaliacaoEspecialAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/conceitual/materia/:idMateria',
    component: AvaliacaoConceitual,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo/:idMateria',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/conteudo',
    component: ConteudoLecionadoTurmaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades',
    component: HabilidadesTrabalhadas,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas',
    component: RegistroAulas,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/registroaulas',
    component: RegistroAulasMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/registroaulas/agrupado',
    component: RegistroAulasMultiseriadoAgrupado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/cadastro',
    component: CadastroRegistroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/edicao/:idRegistroAula',
    component: CadastroRegistroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/cadastro/agrupado',
    component: CadastroRegistroAulaMultiseriadoAgrupado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/edicao/agrupado/:idRegistroAula',
    component: CadastroRegistroAulaMultiseriadoAgrupado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/:idRegistroAula',
    component: RegistroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/agrupado/:idRegistroAula',
    component: RegistroAulaMultiseriadoAgrupado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades',
    component: ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula',
    component: HabilidadesAdquiridasAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula/bimestres',
    component: HabilidadesAdquiridasAlunoBimestres,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/competencias/municipais/matricula/:idMatricula',
    component: CompetenciasMunicipaisAdquiridasAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/habilidades/matricula/:idMatricula',
    component: HabilidadesAdquiridasAlunoMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos',
    component: ObjetivosAprendizagemTrabalhados,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos/matricula/:idMatricula',
    component: ObjetivosAprendizagemAdquiridosAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades/matricula/:idMatricula',
    component: ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia',
    component: CalendarioFrequencia,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia',
    component: CalendarioFrequenciaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/mes',
    component: MesFrequencia,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/mes',
    component: MesFrequenciaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/justificativa/faltas',
    component: JustificativaFaltas,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/justificativa/faltas',
    component: JustificativaFaltasMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/abono',
    component: Abono,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/frequencia/abono',
    component: AbonoMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/data/:data',
    component: Frequencia,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/data/:data',
    component: FrequenciaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual',
    component: TurmaVirtual,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/cadastroaula',
    component: CadastroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/edicaoaula/:idAula',
    component: CadastroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/aula/:idAula',
    component: Aula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/cadastrotarefa',
    component: CadastroTarefa,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/edicaotarefa/:idTarefa',
    component: CadastroTarefa,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa',
    component: Tarefa,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa/envios/aluno/:idAluno',
    component: EnviosAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atafinal',
    component: AtaFinal,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/alfabetizacao/:idDiario/atafinal',
    component: AtaFinalAlfabetizacao,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/atafinal',
    component: AtaFinalTurmaMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/depoimentos',
    component: Depoimentos,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/depoimentos/cadastro',
    component: CadastroDepoimento,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/depoimentos/:idDepoimento',
    component: CadastroDepoimento,
    layout: '/professor',
    redirect: true,
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ouvidoria/chamados',
    component: Ouvidoria,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ouvidoria/chamados/cadastrar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria/editar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria',
    component: ChamadoOuvidoria,
    layout: '/professor',
    redirect: true,
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/dashboard/relatorios/informacoesmatriculas/anoletivo/:idAnoLetivo/diario/:idDiario',
    component: RelatorioInformacoesMatriculas,
    layout: '/professor',
    redirect: true,
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/diarios',
    name: 'Diários',
    icon: 'fas fa-file',
    component: DiariosProfessor,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava',
    name: 'Ambiente virtual',
    icon: 'fas fa-square-root-alt',
    component: AmbienteVirtual,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
    acessoTurmasVirtuais: true,
  },
  {
    path: '/ava/aulas',
    component: ListagemAulas,
    layout: '/professor',
    redirect: true,
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/aulas/cadastro',
    component: CadastroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/aulas/edicao/:idAula',
    component: CadastroAula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/aulas/:idAula',
    component: Aula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais',
    component: TurmasVirtuais,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais/turma/:idTurma/diario/:idDiario/materia/:idMateria',
    component: TurmaVirtual,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais/turma/:idTurma/diario/:idDiario/materia/:idMateria/aula/:idAula',
    component: Aula,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais/turma/:idTurma/diario/:idDiario/materia/:idMateria/cadastrotarefa',
    component: CadastroTarefa,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais/turma/:idTurma/diario/:idDiario/materia/:idMateria/edicaotarefa/:idTarefa',
    component: CadastroTarefa,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais/turma/:idTurma/diario/:idDiario/materia/:idMateria/tarefa/:idTarefa',
    component: Tarefa,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/ava/turmasvirtuais/turma/:idTurma/diario/:idDiario/materia/:idMateria/tarefa/:idTarefa/envios/aluno/:idAluno',
    component: EnviosAluno,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/agenda',
    component: AgendaDiario,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/agenda',
    component: AgendaDiarioMultiseriado,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/contato',
    component: ContatoLogado,
    layout: '/professor',
    redirect: true,
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/tutoriais',
    component: Tutoriais,
    redirect: true,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/professor',
    role: Papel.PROFESSOR.value,
  },

];

export { routesProfile };
export default routes;
